/* Google Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.barlow-thin {
  font-family: "Barlow", serif;
  font-weight: 100;
  font-style: normal;
}

.barlow-extralight {
  font-family: "Barlow", serif;
  font-weight: 200;
  font-style: normal;
}

.barlow-light {
  font-family: "Barlow", serif;
  font-weight: 300;
  font-style: normal;
}

.barlow-regular {
  font-family: "Barlow", serif;
  font-weight: 400;
  font-style: normal;
}

.barlow-medium {
  font-family: "Barlow", serif;
  font-weight: 500;
  font-style: normal;
}

.barlow-semibold {
  font-family: "Barlow", serif;
  font-weight: 600;
  font-style: normal;
}

.barlow-bold {
  font-family: "Barlow", serif;
  font-weight: 700;
  font-style: normal;
}

.barlow-extrabold {
  font-family: "Barlow", serif;
  font-weight: 800;
  font-style: normal;
}

.barlow-black {
  font-family: "Barlow", serif;
  font-weight: 900;
  font-style: normal;
}

.barlow-thin-italic {
  font-family: "Barlow", serif;
  font-weight: 100;
  font-style: italic;
}

.barlow-extralight-italic {
  font-family: "Barlow", serif;
  font-weight: 200;
  font-style: italic;
}

.barlow-light-italic {
  font-family: "Barlow", serif;
  font-weight: 300;
  font-style: italic;
}

.barlow-regular-italic {
  font-family: "Barlow", serif;
  font-weight: 400;
  font-style: italic;
}

.barlow-medium-italic {
  font-family: "Barlow", serif;
  font-weight: 500;
  font-style: italic;
}

.barlow-semibold-italic {
  font-family: "Barlow", serif;
  font-weight: 600;
  font-style: italic;
}

.barlow-bold-italic {
  font-family: "Barlow", serif;
  font-weight: 700;
  font-style: italic;
}

.barlow-extrabold-italic {
  font-family: "Barlow", serif;
  font-weight: 800;
  font-style: italic;
}

.barlow-black-italic {
  font-family: "Barlow", serif;
  font-weight: 900;
  font-style: italic;
}
body {
    width: auto;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Barlow", serif;
    background-color: #080202;
    color: white;
    /* background: #333; */
}
header{
    padding-top: 30px;
    display: flex;
    justify-content: center; /* Centers the nav */
    align-items: center; /* Vertically aligns nav */
    height: 250px;
    /* background-image: url('../img/ab9353ae8639124160578390c10a53db.png'); */
    background-image: url('../img/y782.png');
    background-size: cover;
    background-position: center;
    
}
.mrg-tp {
    margin-top: -60px;
    position: relative;
}
.fnt-stl {
    font-size: 50px;
    line-height: 60px;
}
/* Navbar */
.cont {
    margin-top: -118px;
    width: 85%;
    display: flex;
    /* margin-left: 30px; */
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
    backdrop-filter: blur(33px);

    background: #08020257 !important;

}
.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: rgb(255 255 255);
    background-color: #e5472f61;
    border: var(--bs-border-width) solid rgb(232 71 46 / 10%);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
}
.bg-non {
    background: none !important;
}
.navbar ul {
    list-style: none;
    display: flex;
}

.navbar ul li {
    margin: 0 15px;
}
.nav-link:focus, .nav-link:hover {
    color: rgb(247 74 47);
}
.active-nav {
    color: #fa4b30 !important;
}
.navbar ul li a {
    color: white;
    text-decoration: none;
}

/* Hero Section */
.hero {
    text-align: center;
    
    /* padding: 50px; */
    /* background: #f5f5f5; */
    background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;

}

.hero h2 {
    font-size: 2rem;
}

.btn-primary {
    padding: 10px 20px;
    background: #fa4b30;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn:hover {
    color: white;
    background: #cf351d;
}
h1 {
    font-family: "Barlow", sans-serif;
    font-size: 50px;
    color: #000000;
    text-shadow: -1px -1px 0 #fa4b30, 1px -1px 0 #fa4b30, -1px 1px 0 red, 1px 1px 0 #fa4b30, -1px 0px 0 red, 1px 0px 0 #fa4b30, 0px -2px 0 red, 0px 2px 0 #fa4b30;
    opacity: 0.4;
}
.mrg-95 {
    margin-left: 95px;
}
.btn-get-started {
    background-color: #EE5941;
    /* border-radius: 10px; */
    width: 173px;
    height: 60px;
    top: 42px;
    left: 1100px;
    border-radius: 10px;

}
.logo{
    background-color: transparent !important;
    color: white;
    /* color: transparent; */
}
.logo img {
    height: 50px; /* Adjust as needed */
    background-color: transparent !important;
    color: white;

}
.nav-li{
    background-color: none !important;

    background: none !important;
}
nav ul{
    /* background-color: none !important; */

    background: none !important;
}
nav ul li a{
    background-color: none !important;

    background: none !important;
}
.hero-text{
    background: none !important;
    margin-top: -30px;
    width: 75%;
    font-family: Morion;
    font-weight: 400;
    font-size: 75px;
    line-height: 80px;
    text-align: center;
}
.unveil-possibl{
    background-color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/*collapse*/
details {
    background: #222;
    padding: 17px;
    margin-bottom: 21px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}
summary {
    font-weight: 500;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer; /* Optional: Adds a pointer cursor */
}

summary img {
    margin-left: auto;
}

details[open] summary img {
    transform: rotate(180deg);
}

summary::after {
    content: "";
    display: inline-block;
    /* background: url("assets/img/down-ero.png") no-repeat right; */
    background-size: contain;
    width: 16px;
    height: 16px;
    transition: transform 0.3s;
}

details[open] summary::after {
    transform: rotate(180deg);
}

p {
    margin-top: 10px;
    font-size: 14px;
    color: #ccc;
}
.bg-clr {
    background: #fa4b30;
    padding: 30px;
    border-radius: 9px;
}
.btn-primary-new {
    padding: 10px 20px;
    background: #ffffff;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sec-7 {
            background-color: #fa4b302b;
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
        }

.stats-container {
            display: flex;
            justify-content: space-around;
            align-items: center;
            color: white;
            padding: 40px 20px;
            width: 100%;
            border-radius: 10px;
        }

        .stat-item {
            text-align: center;
        }

        .stat-item i {
            font-size: 40px;
            color: #E63946;
            margin-bottom: 10px;
        }

        .stat-item h2 {
            font-size: 24px;
            margin: 5px 0;
        }

        .stat-item p {
            font-size: 14px;
            color: #ddd;
        }


        .form-container {
            background-color: #2C2929;
            padding: 17px 12px 106px 12px;
            border-radius: 10px;
            width: 361px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        }
        input, textarea {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #444;
            border-radius: 5px;
            background-color: #1E1B1B;
            color: #fff;
        }
        textarea {
            height: 80px;
        }
        .button-nw {
            width: 100%;
            padding: 10px;
            background-color: #E25C4A;
            border: none;
            border-radius: 5px;
            color: white;
            font-size: 16px;
            cursor: pointer;
        }


        .phone-box {
            display: flex;
            align-items: center;
            background-color: #2e2a29;
            padding: 15px 20px;
            border-radius: 10px;
            color: white;
            font-family: Arial, sans-serif;
        }
        .phone-icon {
            width: 40px;
            height: 40px;
            background-color: #e44d3a;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }
        .phone-icon img {
            width: 20px;
            height: 20px;
        }
        .phone-text {
            display: flex;
            flex-direction: column;
        }
        span {
            font-weight: 100;
        }
        .phone-text span {
            font-size: 13px;
            color: #ddd;
            text-align: left;
            font-weight: 100;
        }
        .phone-text strong {
            font-size: 14px;
            color: white;
            text-align: left;
            font-weight: 500;
        }
        footer {
            background-image: url(../img/footer.png), linear-gradient(#1d1d1d, #1d1d1d);
            background-size: cover;
            background-repeat: no-repeat;
            overflow: hidden;
        }

        .footer-column {
            flex: 1;
            margin: 0 20px;
        }
        .footer-column h3 {
            margin-bottom: 15px;
            font-size: 18px;
            color: #ff4500;
        }
        .footer-column p, .footer-column a {
            font-size: 13px;
            color: white;
            text-decoration: none;
            display: block;
            margin-bottom: 8px;
            font-weight: 100;
        }
        .footer-column a:hover {
            color: #ff4500;
        }

@media only screen and (min-width: 1200px) { .sect-2 { display: none !important; }}
@media only screen and (min-width: 767px) and (max-width: 1199px) { .hidden-on-tablet { display: none !important; }}
@media only screen and (max-width: 767px) {
 .mbl-btm { margin-bottom:5px;}
 .mbl-non { display: none;}
 .mrg-95 { margin-left: 0px;}
 .mbl-tp { margin-top: 25px; width: 100%;}
 .sec-2 { display: none !important; }
 .ico {
    width: 19%;
}
.dis-med {
    display: block !important;
}
.logo-med {
    width: 50%;
}
.tog {
    margin-left: 62px;
}
    .ico-curs {
        width: 100%;
    }
    .stats-container {
    display: block;
    justify-content: space-around;
    align-items: center;
    color: white;
    padding: 40px 20px;
    width: 100%;
    border-radius: 10px;
}
.ftr-dsp {
    display: table-footer-group;
}
}


.testimonial-slider {
            padding: 50px 0;
            text-align: center;
        }
        .testimonial-title i {
            color: #ff9800;
        }
        .carousel-item {
            text-align: center;
        }
        .card {
            border: none;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 0px;
        }
        .img-wrapper img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin: 10px auto;
        }
        .carousel-control-prev {
    left: 88px;
}
.carousel-control-next {
    right: 1010px;
}
.carousel-control-prev {
    left: 150px; /* Adjust as needed */
    top: 60%;
    transform: translateY(-50%);
    z-index: 1000;
}

.carousel-control-next {
    right: 150vh; /* Adjust as needed */
    top: 60%;
    transform: translateY(-50%);
    z-index: 1000;
}
.carousel-control-prev img,
.carousel-control-next img {
    transition: transform 0.3s ease-in-out;
}

.carousel-control-prev img:hover,
.carousel-control-next img:hover {
    transform: scale(1.2); /* Zoom in effect */
}

.error {
    color: #ff4500;
    font-size: 14px;
    margin-top: 5px;
  }